/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ lang, title, description, image, article }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage
  } = site.siteMetadata
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    type: article ? `article` : `website`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={seo.title} htmlAttributes={{ lang }}
            titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}>

      <meta name="description" content={seo.description}/>
      <meta name="image" content={seo.image}/>
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.type && <meta property="og:type" content={seo.type} />}
      {seo.title && <meta property="og:title" content={seo.title}/>}
      {seo.description && (
        <meta property="og:description" content={seo.description}/>
      )}
      {seo.image && <meta property="og:image" content={seo.image}/>}
      <meta name="twitter:card" content="summary_large_image"/>
      {seo.title && <meta name="twitter:title" content={seo.title}/>}
      {seo.description && (
        <meta name="twitter:description" content={seo.description}/>
      )}
      {seo.image && <meta name="twitter:image" content={seo.image}/>}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  title: null,
  description: null,
  image: null,
  article: false
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool
}

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultLang: lang
                defaultTitle: title
                defaultDescription: description
                siteUrl: url
                defaultImage: image
            }
        }
    }
`

export default SEO
