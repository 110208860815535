/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import logo from "../images/logo.svg"

import "../scss/app.scss"

const Layout = ({ children }) => {
  return (
    <>
      <main>{children}</main>
      <footer className="content-info">
        <div className="container d-lg-flex justify-content-between">
          <div className="info mb-3">
            <img src={logo} alt="Logo O3ZY" width="125" height="76" />
          </div>

          <div className="info d-flex flex-column justify-content-center text-center text-lg-right">
            <div className="mt-lg-5"><a rel="noreferrer" href="https://www.iubenda.com/privacy-policy/84231219" target={'_blank'} className={'text-white'}>PRIVACY & POLICY</a></div>
            <div className="mt-auto text-gray small">
              © Copyright 2020 - All Rights Reserved O3zy
            </div>
          </div>
        </div>
      </footer>
      <script src="https://d12ue6f2329cfl.cloudfront.net/resources/utm_form-1.2.0.min.js" async />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
